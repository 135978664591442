<template>
  <nav class="nav">
    <span class="nav__logo">
      <a href="/" @click.prevent="goHome"> Elina<br />Albach </a>
    </span>

    <transition-group
      tag="router-link"
      class="nav__links"
      name="nav"
      mode="out-in"
      appear
      v-bind:class="{ 'router-container-link-active': activeLink }"
    >
      <router-link
        v-for="(link, index) in navLinks"
        :key="index"
        :data-index="index"
        class="nav__link nav-item"
        :to="link.url"
        @click="toTop($event, link)"
      >
        {{ link.name }}
      </router-link>

      <!-- only render on about -->
      <div
        v-if="navLinks[0].name == 'Biography'"
        class="nav__languageContainer"
      >
        <router-link class="nav__link" to="/biography"> DE </router-link>
        <router-link class="nav__link" to="/biography-english">
          EN
        </router-link>
      </div>
    </transition-group>
  </nav>

  <!-- mobile EN/DE only render on about -->
  <div
    v-if="navLinks[0].name == 'Biography'"
    class="nav__languageContainer--mobile"
  >
    <router-link class="nav__link" to="/biography"> DE </router-link>
    <router-link class="nav__link" to="/biography-english"> EN </router-link>
  </div>
</template>

<script>
export default {
  data() {
    return {
      activeLink: false,
      navLinks: [
        {
          name: "Biography",
          url: "/biography",
        },
        // {
        //   name: "Projects",
        //   url: "/projects",
        // },
        {
          name: "Media",
          url: "/media",
        },
        {
          name: "Concerts",
          url: "/concerts",
        },
        {
          name: "Contact",
          url: "/kontakt",
        },
        {
          name: "Imprint",
          url: "/elina-albach-imprint",
        },
      ],
    };
  },

  methods: {
    toTop($event, link) {
      //console.dir($event.target.innerHTML);
      let navID = $event.target.dataset.index;
      this.navLinks.splice(navID, 1);
      this.navLinks.unshift(link);

      /* 
      - check if menu item is either concerts or projects (= first of array)
      - if so, make activeLink true -> on nav_links container a new CSS class is conditioanlly rendered: 'router-container-link-active'
      */

      if (
        this.navLinks[0].name == "Concerts" ||
        this.navLinks[0].name == "Projects" ||
        this.navLinks[0].name == "biography"
      ) {
        this.activeLink = true;
      } else {
        this.activeLink = false;
      }
    },
    goHome() {
      // don't judge me :)
      let html = document.querySelector("html");
      html.style.transition = "all 1s ease-in-out";
      html.style.opacity = 0;
      html.style.transform = "translateY(-100px)";
      setTimeout(() => {
        window.location.href = "/";
      }, 1000);
    },
  },
};
</script>


<style lang="scss" scoped>
.nav {
  display: flex;
  justify-content: flex-start;
  line-height: 0.9;
  letter-spacing: -1px;
  margin-right: 40px;
  font-size: 5vh;

  /* super special media query for square aspect ratios */
  @media (min-height: 900px) and (min-width: 1024px) and (max-width: 1150px) {
    font-size: 3.3vh;
  }
  /* super special media query for square aspect ratios */
  @media (min-height: 900px) and (min-width: 1150px) and (max-width: 1400px) {
    font-size: 4vh;
  }

  @include breakExact(1024px) {
    margin-bottom: 60px;
    justify-content: space-between;
  }

  @include breakExact(700px) {
    margin-bottom: 50px;
    margin-right: 0;
    letter-spacing: 0px;
    font-size: 23px;
  }

  &__links {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-right: 20px;
    position: relative;

    @include breakExact(700px) {
      margin-right: 10px;
    }
  }

  &__link {
    text-decoration: none;
    text-transform: uppercase;
    color: $elina-highlight;
    text-align: left;
    transition: all 0.3s ease-in-out;

    &:hover {
      color: $white;
    }

    /* clear hover on mobile */
    @include breakExact(1024px) {
      &:hover {
        color: $elina-highlight;
      }
    }
  }

  &__languageContainer {
    width: 100%;
    margin-top: 40px;
    display: flex;

    a {
      text-decoration: none;
      text-transform: uppercase;
      color: $elina-highlight;
      cursor: pointer;
      letter-spacing: -1px;
      transition: all 0.3s ease-in-out;

      &:first-child {
        margin-right: 40px;
      }

      &:hover {
        color: $white;
      }

      /* clear hover on mobile */
      @include breakExact(1024px) {
        &:hover {
          color: $elina-highlight;
        }
      }
    }

    &--mobile {
      display: none;

      .router-link-active {
        color: $white;
      }

      a:first-child {
        margin-right: 10px;
      }

      @include breakExact(1024px) {
        display: block;

        .nav__link {
          font-size: 2.3vh;
        }
      }
    }

    @include breakExact(1024px) {
      display: none;
    }
  }

  &__logo {
    color: $white;
    //font-size: 5vh;
    text-decoration: none;
    text-transform: uppercase;
    letter-spacing: -1px;
    margin-right: 60px;

    a {
      color: $white;

      &:hover {
        color: $elina-highlight;
      }
    }

    @include breakExact(700px) {
      font-size: 23px;
      letter-spacing: 0px;
    }
  }

  /* active black menu item */
  .router-link-active {
    //.nav__links a:first-child {
    color: $white;
  }

  /*   .nav-item {
    transition: all 0.8s ease;
  } */

  .nav-enter-from {
    opacity: 0;
    transform: translateY(100px);
  }
  .nav-enter-to {
    opacity: 1;
  }
  .nav-enter-active {
    transition: all 0.5s ease;
    //position: absolute;
  }
  .nav-leave-to {
    opacity: 0;
    transform: translateY(-100px);
  }
  .nav-leave-active {
    transition: all 0.5s ease;
    //position: absolute;
  }
}
</style>