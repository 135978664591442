<template>
  <div v-if="content !== null" class="page__content">
    <div v-if="videoContent !== null" class="videos">
      <h1>VIDEOS</h1>
      <article
        v-for="video in videoContent.acf.videos"
        :key="video"
        class="videos__video"
      >
        <div
          class="videos__previewImageWrapper"
          @click="enlargeModal(video.video_embed_iframe_code)"
        >
          <img
            v-if="video.preview_image"
            :src="video.preview_image.sizes.large"
            :alt="video.preview_imagedescription"
            srcset=""
            class="videos__previewImage"
          />
        </div>
        <div class="videos__info" v-html="video.info"></div>
      </article>

      <transition name="modal" mode="in-out">
        <div v-show="openModal" class="modal">
          <div class="iFrameCont" ref="iFrameContainer"></div>
          <div class="modal__closeBtn" @click="closeModal">×</div>
        </div>
      </transition>
    </div>
    <!-- end of video content -->
    <!-- start of regular page content -->
    <div v-html="content"></div>
  </div>
  <div v-else>Es lädt…</div>
</template>


<script>
export default {
  data() {
    return {
      content: null,
      videoContent: null, // API post response from wordpress
      openModal: false, // boolean toggle to open/close modal
    };
  },

  async mounted() {
    this.getData();
  },
  methods: {
    enlargeModal(url) {
      this.openModal = true;

      //emit to Audio Player, so it's stop playing
      this.$emit("stopAudio", this.openModal);

      // wait until $refs is not undefinied anymore
      const interval = setInterval(() => {
        if (this.$refs.iFrameContainer) {
          // get div to insert iFrame into
          let iFrameContainer = this.$refs.iFrameContainer;
          iFrameContainer.innerHTML = url;
          // include autoplay at the end of the url string
          iFrameContainer.children[0].src += "?autoplay=1";
          // clear interval, because $refs is defined now
          clearInterval(interval);
        }
      }, 50);
    },
    closeModal() {
      //close modal
      this.openModal = false;
      //destroy iFrame within modal
      this.$refs.iFrameContainer.innerHTML = "";

      //emit to Audio Player, so it's stop playing
      this.$emit("stopAudio", this.openModal);
    },

    async getData() {
      const response = await fetch(
        `${process.env.VUE_APP_API_ROUTE}/wp-json/wp/v2/pages?slug=${this.$route.params.page}`
      );
      const data = await response.json();
      //console.log(data);
      this.content = data[0].content.rendered;

      if (this.$route.path == "/media") {
        const ACF_raw = await fetch(
          `${process.env.VUE_APP_API_ROUTE}/wp-json/acf/v3/pages/${data[0].id}`
        );
        const acf = await ACF_raw.json();
        this.videoContent = acf;
        //console.log(this.videoContent);
      }
    },
  },
};
</script>

<style lang="scss">
.page {
  &__content {
    line-height: 1.1;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      &:first-of-type {
        margin-bottom: 20px;
      }

      &:nth-of-type(2) {
        margin-top: 60px;
        margin-bottom: 20px;
      }
    }

    p {
      margin-left: 100px;
      text-indent: -100px;

      @include breakExact(700px) {
        margin-left: 30px;
        text-indent: -30px;
      }
    }

    /* worpdress 50%, 75% size in gutenberg
     */
    .is-resized {
      &::after {
        width: 50%;
      }

      &:first-child {
        margin-top: 0;
      }

      img {
        width: 50%;
        height: auto;
        display: block;
      }
    }

    ul {
      margin: 20px 0;
    }

    .videos {
      &__video {
        margin-bottom: 60px;

        @include breakExact(700px) {
          margin-bottom: 30px;
        }
      }

      &__previewImage {
        margin-bottom: 20px;
        @include breakExact(700px) {
          margin-bottom: 10px;
        }
      }

      &__previewImageWrapper {
        position: relative;
        cursor: pointer;
        min-height: 300px;
        background: $black;
        margin-bottom: 20px;

        @include breakExact(700px) {
          min-height: 160px;
          margin-bottom: 10px;
        }

        &:after {
          position: absolute;
          top: 40%;
          left: 50;
          width: 100%;
          height: 100%;
          content: "PLAY";
          font-size: 5vh;
          color: white;
          text-align: center;
          transition: all 0.3s ease-in-out;

          @include breakExact(700px) {
            font-size: 24px;
          }

          /* super special media query for square aspect ratios */
          @media (min-height: 900px) and (min-width: 1024px) and (max-width: 1150px) {
            font-size: 3.3vh;
          }
          /* super special media query for square aspect ratios */
          @media (min-height: 900px) and (min-width: 1150px) and (max-width: 1400px) {
            font-size: 4vh;
          }
        }
        &:hover {
          &::after {
            color: $black;
          }
        }
      }
    }
  }
}

.modal {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: $black;
  padding: 60px;
  z-index: 1;

  @include breakExact(700px) {
    padding: 14px 10px;
  }

  div iframe {
    /* 120px = 2x 60px wie auf frontpage padding */
    width: calc(100vw - 120px);
    height: calc(100vh - 120px);

    @include breakExact(700px) {
      width: calc(100vw - 20px);
      height: calc(100vh - 110px);
    }
  }

  &__closeBtn {
    position: absolute;
    top: 0;
    right: 0;
    font-size: 30px !important;
    line-height: 0.9;
    cursor: pointer;
    padding: 20px;
    color: $gray-700;
    transition: all 0.3s ease-in-out;

    @include breakExact(700px) {
      font-size: 24px;
      padding: 14px 10px;
    }

    &:hover {
      color: $white;
    }
  }

  &-enter-from {
    opacity: 0;
  }

  &-enter-active,
  &-leave-active {
    transition: opacity 0.5s ease;
  }

  &-leave-to {
    opacity: 0;
  }
}
</style>