<template>
  <Intro />
</template>

<script>
// @ is an alias to /src
import Intro from "@/components/Intro";

export default {
  name: "Home",
  components: {
    Intro,
  },
};
</script>
