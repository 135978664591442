<template>
  <div class="player" v-if="music !== null">
    <div class="player__controls">
      <button @click="prev" class="player__controls__skip">PREV</button>
      <button @click="play" v-if="!playing" class="player__controls__play">
        PLAY
      </button>
      <button @click="play" v-else class="player__controls__play">STOP</button>
      <button @click="next" class="player__controls__skip">NEXT</button>
    </div>
    <p v-show="music !== []" class="player__info" ref="playerInfo">
      <span ref="playerText">
        {{ music[musicIndex].audio_info }}&emsp;&emsp;&emsp;
      </span>
    </p>
  </div>
</template>

<script>
import TextRoller from "@/components/textRoller.js";

export default {
  data() {
    return {
      music: null,
      musicIndex: 0,
      playing: false,
      audioPlayer: null,
      marquee: null,
    };
  },
  props: ["stopAudio"],
  updated() {
    if (this.stopAudio == true && this.playing == true) {
      this.play();
    }
  },

  async mounted() {
    let API_ROUTE = `${process.env.VUE_APP_API_ROUTE}/wp-json/acf/v3/pages/147`;
    const ACF_raw = await fetch(API_ROUTE);
    const acf = await ACF_raw.json();
    this.music = acf.acf.audio;
    this.audioPlayer = new Audio(this.music[this.musicIndex].audio_file);

    const interval = setInterval(() => {
      if (this.$refs.playerInfo) {
        //console.log(this.$refs.playerInfo);
        this.marquee = new TextRoller(this.$refs.playerInfo);
        clearInterval(interval);
      }
    }, 50);
  },
  methods: {
    play() {
      this.playing = !this.playing;
      if (this.playing) {
        this.audioPlayer.play();
        this.marquee.start();
      } else {
        this.audioPlayer.pause();
        this.marquee.stop();
      }
    },
    next() {
      this.musicIndex++;

      //this.$refs.playerInfo.textContent = "";
      if (this.musicIndex > this.music.length - 1) {
        this.musicIndex = 0;
      }
      this.audioPlayer.src = this.music[this.musicIndex].audio_file;
      this.playing = true;
      this.audioPlayer.play();
      this.resetTextRoller();
      if (!this.marquee._running) {
        this.marquee.start();
      }
    },
    prev() {
      this.musicIndex--;
      if (this.musicIndex < 0) {
        this.musicIndex = this.music.length - 1;
      }
      this.audioPlayer.src = this.music[this.musicIndex].audio_file;
      this.playing = true;
      this.audioPlayer.play();
      this.resetTextRoller();
      if (!this.marquee._running) {
        this.marquee.start();
      }
    },
    resetTextRoller() {
      this.$refs.playerInfo.children[0].textContent =
        this.music[this.musicIndex].audio_info;
      this.$refs.playerInfo.children[1].textContent =
        this.music[this.musicIndex].audio_info;

      this.marquee.reset();
    },
  },
};
</script>


<style lang="scss" scoped>
.player {
  position: fixed;
  bottom: 0;
  left: 0;
  width: calc(50vw - 60px - 40px - 30px);
  height: 60px;
  background: $elina-highlight;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: 60px;
  padding: 10px 14px;
  z-index: 1;

  @include breakExact(1024px) {
    width: calc(100% - 120px);
    margin-right: 60px;
  }

  @include breakExact(700px) {
    width: calc(100% - 20px);
    margin-left: 10px;
    padding: 10px;
    height: 40px;
  }

  &__controls {
    display: flex;
    justify-content: space-between;
    min-width: 140px;
    margin-right: 10px;

    @include breakExact(700px) {
      width: 100%;
      margin-right: 0;
    }

    button {
      background: none;
      border: none;
      cursor: pointer;
      margin-right: 10px;
      font-size: 2.3vh;
      letter-spacing: -0.04vh;
      min-width: 6.3vh;
      transition: all 0.3s ease-in-out;
      font-family: "ABCSynt", serif;
      color: $gray-700;

      /* super special media query for square aspect ratios */
      @media (min-height: 900px) and (min-width: 1024px) and (max-width: 1150px) {
        font-size: 1.8vh;
      }
      /* super special media query for square aspect ratios */
      @media (min-height: 900px) and (min-width: 1150px) and (max-width: 1400px) {
        font-size: 2vh;
      }

      @include breakExact(700px) {
        letter-spacing: 0;
      }

      &:hover {
        color: $white;
      }
    }

    &__skip {
      letter-spacing: -0.3vh;
    }
  }

  &__info {
    flex: 1;
    overflow: hidden;
    white-space: nowrap;
    color: $gray-700;

    @include breakExact(700px) {
      display: none;
    }
  }
}

audio {
  display: none;
}
</style>