<template>
  <div v-if="projects !== null" class="projects">
    <article
      v-for="(project, index) in projects"
      :key="index"
      class="projects__content"
    >
      <router-link
        :to="{
          name: 'Project',
          params: {
            project: project.slug,
          },
        }"
      >
        <h1 class="projects__heading">{{ project.title.rendered }}</h1>

        <figure class="projects__imageContainer">
          <img
            class="projects__image"
            :src="project._embedded['wp:featuredmedia'][0].source_url"
          />
        </figure>
      </router-link>
    </article>
  </div>
  <div v-else>Es lädt…</div>
</template>

<script>
import { onUpdated } from "@vue/runtime-core";
export default {
  data() {
    return {
      projects: null,
    };
  },
  // watch: {
  //   $route: "getData",
  // },
  async beforeMount() {
    this.getData();
  },
  methods: {
    async getData() {
      const response = await fetch(
        `${process.env.VUE_APP_API_ROUTE}/wp-json/wp/v2/projects?_embed&filter[orderby]=date&order=asc`
      );
      const data = await response.json();
      this.projects = data;
      //console.log(data);
    },
  },
};
</script>

<style lang="scss" scoped>
.projects {
  &__heading {
    margin-bottom: 20px;
  }

  &__imageContainer {
    position: relative;
    margin-bottom: 100px;
    cursor: pointer;
    min-height: 600px;
    height: 50vh;
    width: auto;

    @include breakExact(1024px) {
      margin-bottom: 80px;
      min-height: initial;
    }

    @include breakExact(700px) {
      margin-bottom: 40px;
    }
  }

  &__image {
    display: block;
    object-fit: cover;
    height: 50vh;
    min-height: 600px;

    @include breakExact(1024px) {
      min-height: initial;
    }
  }
}
</style>