<template>
  <div v-if="futureConcerts !== null" class="concerts">
    <article
      v-for="(futureConcert, index) in futureConcerts"
      :key="index"
      class="concerts__concert"
    >
      <div class="concerts__timeContainer">
        <h2>{{ formatDate(futureConcert.post_date) }}</h2>
        <h2>{{ formatTime(futureConcert.post_date) }}</h2>
      </div>
      <h1 class="concerts__heading" v-html="futureConcert.post_title" />
      <p v-html="futureConcert.post_content" />
    </article>

    <p class="concertArchive__noConcerts" v-if="futureConcerts.length === 0">
      Neue Konzerte sind in Planung…
    </p>

    <div class="concertArchive" v-if="pastYears.length">
      <h2 class="concertArchive__heading" v-for="year in pastYears" :key="year">
        <router-link
          :to="{
            name: 'ConcertsArchive',
            params: {
              year: year,
            },
          }"
        >
          {{ year }}
        </router-link>
      </h2>
    </div>
  </div>
  <div v-else>Es lädt…</div>
</template>

<script>
export default {
  data() {
    return {
      futureConcerts: null,
      pastConcerts: null,
      pastYears: [],
    };
  },
  async mounted() {
    this.getData();
  },
  methods: {
    async getData() {
      // use custom wordpress endpoint
      // category=2: Elina Albach Konzerte
      const fetchConcerts = await fetch(`${process.env.VUE_APP_API_ROUTE}/wp-json/custom/v1/all-concerts?category=2`);

      const concertsData = await fetchConcerts.json();

      this.futureConcerts = concertsData.filter(concert => concert.post_status == "future");
      this.pastConcerts = concertsData.filter(concert => concert.post_status == "publish");
      //console.log(this.pastConcerts);

      /* create years archive */
      this.pastConcerts.forEach((concert) => {
        let year = new Date(concert.post_date).getFullYear();
        if (!this.pastYears.includes(year)) {
          this.pastYears.unshift(year);
        }
      });
    },
    formatDate(date) {
      return new Date(date).toLocaleDateString('de-DE');
    },
    formatTime(date) {
      return new Date(date).toLocaleTimeString('de-DE', {
        hour: '2-digit',
        minute: '2-digit'
      });
    },
  },
};
</script>

<style lang="scss">
.concerts {
  &__concert {
    margin-bottom: 100px;

    @include breakExact(1024px) {
      margin-bottom: 60px;
    }

    @include breakExact(700px) {
      margin-bottom: 40px;
    }

    &:last-of-type {
      @include breakExact(1024px) {
        margin-bottom: 80px;
      }

      @include breakExact(700px) {
        margin-bottom: 60px;
      }
    }
  }

  &__timeContainer {
    display: flex;
    justify-content: space-between;
  }

  &__heading {
    margin-bottom: 20px;
  }
}

.concertArchive {
  &__noConcerts {
    margin-bottom: 100px;
  }

  &__heading a {
    color: $elina-highlight;
    cursor: pointer;
    font-variant-numeric: tabular-nums;
    transition: all 0.3s ease-in-out;

    &:hover {
      color: $white;
    }
  }
}
</style>
