<template>
  <section class="frontpage">
    <div class="nav__Container">
      <Nav />
      <Player :stopAudio="openModalMuteAudio" />

      <ul
        class="project__gallery"
        v-html="gallery"
        v-if="gallery !== null"
      ></ul>
    </div>

    <router-view class="content" v-slot="{ Component, route }">
      <transition :name="route.meta.transition || 'route'">
        <Suspense>
          <keep-alive>
            <component
              :is="Component"
              :key="route.path"
              @updateGallery="receiveGallery($event)"
              @stopAudio="stopAudio"
            />
          </keep-alive>
        </Suspense>
      </transition>
    </router-view>
  </section>
</template>

<script>
import Intro from "@/components/Intro.vue";
import Player from "@/components/Player.vue";
import Nav from "@/components/Nav.vue";

export default {
  data() {
    return {
      gallery: null, // emited gallery HTML
      emitedFrom: null, //emited route path to check if we are on the project route
      openModalMuteAudio: false,
    };
  },
  watch: {
    /* watch, if we are on the Project-Route (where the gallery should be displayed only) */
    $route(to) {
      /* if we are on the project route: Check, if the emited gallery route (comingFrom) is the same */
      if (to.params !== this.emitedFrom) {
        this.gallery = "";
      }
    },
  },
  methods: {
    /* receive wp-gallery from Project.vue and display it on the right */
    /* Why? Gallery data is coming from API inside Project.vue but needs to be displayed on main-component */
    /* Can't be positioned absolute; Needs it's real height in the DOM */
    receiveGallery(emitedGallery) {
      if (this.$route.path == emitedGallery.comingFrom) {
        this.gallery = emitedGallery.gallery;
        this.emitedFrom = emitedGallery.comingFrom;
      } else {
        this.gallery = "";
      }
    },
    stopAudio() {
      // emit to Audio player, if video modal gets openend to stop audio from playing
      this.openModalMuteAudio = !this.openModalMuteAudio;
    },
  },
  components: {
    Intro,
    Nav,
    Player,
  },
};
</script>

<style lang="scss">
@font-face {
  font-family: "ABCSynt";
  src: url("./assets/fonts/ABCSynt-Regular.woff") format("woff"),
    url("./assets/fonts/ABCSynt-Regular.woff2") format("woff2");
  font-style: normal;
}

@font-face {
  font-family: "Continuum";
  src: url("./assets/fonts/Continuum-Regular.woff") format("woff"),
    url("./assets/fonts/Continuum-Regular.woff") format("woff2");
}

#app {
  font-family: "ABCSynt", serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 1920px;
  max-width: 100%;
  margin: 0 auto;
  background: $elina-bg;
  color: $gray-700;
}

body {
  background: $elina-bg;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

body {
  animation: welcome 1s forwards ease-in-out;
}

@keyframes welcome {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

::selection {
  background: $white;
  color: $black;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  text-transform: uppercase;
  font-weight: normal;
  font-size: 5vh;
  line-height: 0.9;

  @include breakExact(700px) {
    font-size: 24px;
  }

  /* super special media query for square aspect ratios */
  @media (min-height: 900px) and (min-width: 1024px) and (max-width: 1150px) {
    font-size: 3.3vh;
  }
  /* super special media query for square aspect ratios */
  @media (min-height: 900px) and (min-width: 1150px) and (max-width: 1400px) {
    font-size: 4vh;
  }
}

p {
  font-size: 2.3vh;
  line-height: 1.1;

  /* super special media query for square aspect ratios */
  @media (min-width: 1024px) and (min-height: 900px) and (max-width: 1400px) {
    font-size: 2vh;
  }
}

ul {
  li {
    font-size: 2.3vh;
    line-height: 1.1;
    list-style: none;
  }
}

a {
  text-decoration: none;
  color: $gray-700;
  transition: all 0.3s ease-in-out;

  &:hover {
    color: $white;
  }

  @include breakExact(1024px) {
    &:hover {
      color: $gray-700;
    }
  }
}

figure {
  margin: 20px 0;
  max-width: 75%;

  @include breakExact(700px) {
    margin: 10px 0;
    max-width: 100%;
  }

  &:first-of-type {
    margin-top: 0;
  }
}

/* because in gallery figures are nested in figures */
.wp-block-gallery {
  &:after {
    display: none;
  }
}

img {
  display: block;
  width: 100%;
  height: auto;
}

.frontpage {
  display: flex;
  padding: 60px 60px 120px 60px;

  @include breakExact(1024px) {
    flex-direction: column;
  }

  @include breakExact(700px) {
    padding: 14px 10px 80px 10px;
  }
}

.nav,
.content {
  //flex: 1;
  flex-basis: 100%;
  //width: 50%;
}

/* To display the project gallery underneath */
.nav__Container {
  width: 100%;
  .project__gallery {
    margin-top: 400px;
    width: 50%;

    @include breakExact(1024px) {
      display: none;
    }
  }
}

.content {
  padding: 0 20px;
  position: relative;

  @include breakExact(1024px) {
    padding: 0;
    margin-top: 20px;
  }

  /* correct font-size for unformated wordpress posts */
  div {
    font-size: 2.3vh;
  }
}

/* page transitions */
.route-enter-from {
  opacity: 0;
  //transform: translateX(-20px);
}
.route-enter-to {
  opacity: 1;
}
.route-enter-active {
  transition: all 1s ease-out;
}
.route-leave-to {
  opacity: 0;
  //transform: translateX(20px);
}
.route-leave-active {
  transition: all 0s;
  position: absolute;
}

/* Intro Transition */

.IntroTransition-enter-from {
  opacity: 1;
}
.IntroTransition-leave-to {
  opacity: 0;
  transform: scale(0);
}
.IntroTransition-leave-active,
.IntroTransition-enter-active {
  transition: all 0.5s;
}
</style>
