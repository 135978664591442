import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import Page from '../views/Page.vue'
import Projects from '../views/Projects.vue'
import Project from '../views/Project.vue'
import Concerts from '../views/Concerts.vue'
import ConcertsArchive from '../views/ConcertsArchive.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {transition: 'IntroTransition'}
  },
  {
    path: '/:page',
    name: 'Seite',
    component: Page,
    props: true
  },
  {
    path: '/projects',
    name: 'Projects',
    component: Projects
  },
  {
    path: '/projects/:project',
    name: 'Project',
    component: Project,
    props: true
  },
  {
    path: '/concerts',
    name: 'Concerts',
    component: Concerts
  },
  {
    path: '/concerts/:year',
    name: 'ConcertsArchive',
    component: ConcertsArchive,
    props: true
  },
  
  // {
  //   path: '/:page',
  //   name: 'Seite',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/Page.vue'),
  //   props: true
  // }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior (to, from, savedPosition) {
    window.scrollTo(0, 0)
  }
})

export default router
