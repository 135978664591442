<template>
  <div class="content">
    <div class="project__header project__header--concert">
      <h1 class="project__heading">
        {{ $route.params.year }}
      </h1>
      <router-link to="/concerts"> × </router-link>
    </div>

    <div v-if="pastConcerts.length">
      <article v-for="(pastConcert, index) in pastConcerts" :key="index" class="concerts__concert">
        <div class="concerts__timeContainer">
          <h2>{{ pastConcert.formated_date }}</h2>
          <h2>{{ pastConcert.formated_time }}</h2>
        </div>
        <h2 class="concerts__heading" v-html="pastConcert.title.rendered" />
        <p v-html="pastConcert.content.rendered" />
      </article>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      pastConcerts: [],
    }
  },
  async mounted() {
    this.getData()
    //console.log(this.$route);
  },
  methods: {
    async getData() {
      const response = await fetch(
        /* route according to WP-plugin: WP_Query Route To REST API */
        `${process.env.VUE_APP_API_ROUTE}/wp-json/wp_query/args/?post_type=concerts&post_status=publish&year=${this.$route.params.year}&cat="2"&posts_per_page=200`
      )
      const data = await response.json()
      this.pastConcerts = await data
    },
  },
}
</script>